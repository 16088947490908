import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";
import { IFetchOutletsFromBusinessApiResponse } from "./api-response.factory";

export type IState = "ongoing" | "paused" | "disabled";
export type IService = "dinein" | "delivery" | "pickup";
export type IOutletState = "active" | "inactive";

export interface IFetchOutletsFromBusiness {
  id: string;
  name: string;
  state: IOutletState;
  orderingServiceState: {
    delivery: IState;
    dinein: IState;
    pickup: IState;
  };
}

export class FetchOutletsFromBusinessError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchOutletsFromBusiness");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

const serviceMap = {
  delivery: "delivery",
  pickup: "pickup",
  dinein: "dine-in",
};

function getServiceState(
  data: IFetchOutletsFromBusinessApiResponse["data"][0]["ordering_services"],
  service: IService
): IState {
  const orderingService = data.find((o) => o.slug === serviceMap[service]);

  if (!orderingService) {
    return "disabled";
  }

  if (orderingService.state === "disabled") {
    return "disabled";
  }

  if (orderingService.state === "enabled") {
    return "ongoing";
  }

  if (orderingService.state === "paused") {
    return "paused";
  }

  return "disabled";
}

function getOutletState(state: string): IOutletState {
  return state === "active" ? "active" : "inactive";
}

export async function fetchOutletsFromBusiness(
  businessId: string
): Promise<Array<IFetchOutletsFromBusiness>> {
  let apiResponse: AxiosResponse<IFetchOutletsFromBusinessApiResponse>;

  try {
    apiResponse = await http.get<IFetchOutletsFromBusinessApiResponse>(
      `/api/v3/businesses/${businessId}/outlets`,
      {
        params: {
          include: "ordering_services",
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchOutletsFromBusinessError(error);
  }

  return apiResponse.data.data.map((obj) => ({
    id: obj.id,
    name: obj.name,
    state: getOutletState(obj.state),
    orderingServiceState: {
      delivery: getServiceState(obj.ordering_services, "delivery"),
      dinein: getServiceState(obj.ordering_services, "dinein"),
      pickup: getServiceState(obj.ordering_services, "pickup"),
    },
  }));
}
