














































































































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
// @ts-ignore
import bus from "@/utils/events/bus.js";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import {
  OrgHeaderInfo,
  AtomIcon,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomModal,
} from "@/v2/new-design-system";
import {
  fetchOutletsFromBusiness,
  IFetchOutletsFromBusiness,
} from "@/v2/repo/fetch-outlets-from-business";
import { pauseOrdersForOutlet } from "@/v2/repo/pause-orders-for-outlet";
import { resumeOrdersForOutlet } from "@/v2/repo/resume-orders-for-outlet";
import { PauseOrdersSwitchStateButton } from "../switch-state-button";
import { PauseOrdersUpdateState } from "../update-state";
import { useHelpers } from "@/v2/composable/use-helpers";
import { IPauseOrdersService } from "../domain/service.enum";
import { IPauseOrdersState } from "../domain/state.enum";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("pause-orders-main");

export default defineComponent({
  name: "PauseOrdersMain",
  components: {
    OrgHeaderInfo,
    AtomText,
    AtomIcon,
    AtomButton,
    PauseOrdersSwitchStateButton,
    AtomModal,
    PauseOrdersUpdateState,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const outlets = ref<IFetchOutletsFromBusiness[]>([]);
    const isLoading = ref(false);
    const { name: businessName, logo: businessLogo } =
      useHelpers().getCurrentBusiness(props.businessId);

    function onTestSound() {
      bus.$emit("testSound");
    }

    const changeStateProps = ref<
      | {
          service: IPauseOrdersService;
          outletId: string;
        }
      | undefined
    >();
    function onOpenPauseOrdersModal(
      service: IPauseOrdersService,
      outletId: string
    ) {
      changeStateProps.value = {
        service: service,
        outletId: outletId,
      };
    }

    async function onPauseOrders(datetime: Date) {
      if (!changeStateProps.value) return;

      isLoading.value = true;

      let success = false;
      try {
        success = await pauseOrdersForOutlet(
          changeStateProps.value.outletId,
          changeStateProps.value.service,
          datetime
        );
      } catch (e: any) {
        generalErrorToast();
        report(e);
      }

      if (success) {
        await fetchOutlets();
        isLoading.value = false;
        closePauseOrderModal();
      }
    }

    async function onResumeOrders(
      service: IPauseOrdersService,
      outletId: string
    ) {
      let success = false;
      try {
        success = await resumeOrdersForOutlet(outletId, service);
      } catch (e: any) {
        generalErrorToast();
        report(e);
      }

      if (success) {
        await fetchOutlets();
      }
    }

    async function fetchOutlets() {
      try {
        outlets.value = await fetchOutletsFromBusiness(props.businessId);
      } catch (e: any) {
        generalErrorToast();
        report(e);
      }
    }

    function closePauseOrderModal() {
      changeStateProps.value = undefined;
    }

    onMounted(async () => {
      await fetchOutlets();
    });

    function getOutletState(state: string, type: string) {
      return state === "inactive" ? IPauseOrdersState.DISABLED : type;
    }

    return {
      getOutletState,
      css,
      t,
      outlets,
      businessName,
      businessLogo,
      onOpenPauseOrdersModal,
      onTestSound,
      isLoading,
      AtomIcon,
      closePauseOrderModal,
      AtomButton,
      bus,
      onResumeOrders,
      onPauseOrders,
      changeStateProps,
      IPauseOrdersService,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomText,
      AtomTextTypeEnum,
      AtomTextTagEnum,
    };
  },
});
