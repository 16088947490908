import { IRepoErrors } from "../errors";
import { http } from "../http";

export class ResumeOrdersForOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: resumeOrdersForOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

const serviceMap = {
  delivery: "delivery",
  pickup: "pickup",
  dinein: "dine-in",
};

export async function resumeOrdersForOutlet(
  outletId: string,
  service: keyof typeof serviceMap
): Promise<boolean> {
  try {
    await http.post(
      `/api/v3/outlets/${outletId}/ordering-services/${serviceMap[service]}/resume`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new ResumeOrdersForOutletError(error);
  }
  return true;
}
