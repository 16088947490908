import { arraySample } from "@/v2/util";
import { each, makeFactory } from "factory.ts";
import * as faker from "faker";

export const FetchOutletsFromBusinessApiResponseFactory =
  makeFactory<IFetchOutletsFromBusinessApiResponse>({
    data: [],
  }).extend({
    data: makeFactory<IOutlet>({
      ordering_services: each(() => [
        {
          slug: "delivery",
          state: arraySample(["disabled", "enabled", "paused"]),
        },
        {
          slug: "dine-in",
          state: arraySample(["disabled", "enabled", "paused"]),
        },
        {
          slug: "pickup",
          state: arraySample(["disabled", "enabled", "paused"]),
        },
      ]),
      id: each(() => faker.datatype.uuid()),
      name: each(() => faker.company.companyName()),
      state: arraySample(["inactive", "active"]),
    }).buildList(10),
  });

export interface IFetchOutletsFromBusinessApiResponse {
  data: Array<IOutlet>;
}

interface IOutlet {
  id: string;
  name: string;
  state: string;
  ordering_services: Array<IOrderingService>;
}

interface IOrderingService {
  slug: string;
  state: "disabled" | "enabled" | "paused";
}
