

















































































import { tc, t } from "@/i18n";
import {
  AtomText,
  AtomRadio,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import { IPauseOrdersService } from "../domain/service.enum";
import { IPauseOrdersState } from "../domain/state.enum";
import dayjs from "dayjs";

const css = bemBuilder("pause-orders-update-state");

export default defineComponent({
  name: "PauseOrdersUpdateState",
  components: {
    AtomRadio,
    AtomText,
    AtomButton,
  },
  props: {
    service: {
      type: String as PropType<IPauseOrdersService>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(datetime: Date) => void>,
      required: true,
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const hoursAmount = ref("1");

    const requestUpdate = () => {
      const pauseUntil = dayjs().add(parseInt(hoursAmount.value, 10), "hours");
      props.onChange(pauseUntil.toDate());
    };

    const serviceLabel = computed(() => {
      if (props.service === IPauseOrdersService.DELIVERY) {
        return t("module.pause_orders.delivery_term");
      }

      if (props.service === IPauseOrdersService.PICKUP) {
        return t("module.pause_orders.pickup_term");
      }

      return t("module.pause_orders.dinein_term");
    });

    return {
      t,
      tc,
      css,
      hoursAmount,
      serviceLabel,
      requestUpdate,
      AtomTextTypeEnum,
      AtomTextTagEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
