













































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomIcon,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { IPauseOrdersService } from "../domain/service.enum";
import { IPauseOrdersState } from "../domain/state.enum";

const css = bemBuilder("pause-orders-switch-state-button");

export default defineComponent({
  name: "PauseOrdersSwitchStateButton",
  components: {
    AtomButton,
    AtomIcon,
  },
  props: {
    type: {
      type: String as PropType<IPauseOrdersService>,
      required: true,
    },
    state: {
      type: String as PropType<IPauseOrdersState>,
      required: true,
    },
    onResume: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onPause: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const displayTypeLabel = computed(() => {
      if (props.type === IPauseOrdersService.DINEIN) {
        return t("module.pause_orders.switch_state_button.service_dinein");
      }

      if (props.type === IPauseOrdersService.PICKUP) {
        return t("module.pause_orders.switch_state_button.service_pickup");
      }

      return t("module.pause_orders.switch_state_button.service_delivery");
    });

    const isOngoing = computed(() => props.state === IPauseOrdersState.ONGOING);

    const isDisabled = computed(
      () => props.state === IPauseOrdersState.DISABLED
    );

    const onClick = () => {
      if (isOngoing.value) {
        props.onPause();
        return;
      }
      props.onResume();
    };

    return {
      css,
      t,
      isOngoing,
      isDisabled,
      onClick,
      displayTypeLabel,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
